import React from 'react';

const ChemotionLogo = ({ collapsed }) => {
  const width = collapsed ? "84" : "160";

  const styles = {
    blue: {
      fill: "#2495cf",
    },
    red: {
      fill: "#FF5555",
    },
    grey: {
      fill: "#4f5659",
    },
  };

  return (
    <svg
      width={width}
      height="88"
      viewBox={`0 0 ${width} 88`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Chemotion Logo</title>
      <g id="logo">
        <g id="icon">
          <path style={styles.blue} d="M44.085,62.333c-10.106,0-18.333-8.227-18.333-18.338s8.227-18.338,18.333-18.338c4.695,0,9.118,1.753,12.531,4.945l2.699-2.696c-4.13-3.911-9.513-6.059-15.229-6.059-12.212,0-22.146,9.937-22.146,22.151s9.935,22.153,22.146,22.153c5.716,0,11.099-2.146,15.227-6.059l-2.701-2.696c-3.406,3.187-7.829,4.938-12.526,4.938"/>
          <path style={styles.grey} d="M62.983,64.206h0c-4.961,4.661-11.638,7.517-18.983,7.517-15.313,0-27.725-12.414-27.725-27.725s12.412-27.725,27.725-27.725c7.305,0,13.95,2.825,18.902,7.443h0l2.87-3.168c-5.712-5.304-13.362-8.548-21.772-8.548-17.673,0-32,14.327-32,32s14.327,32,32,32c8.448,0,16.13-3.273,21.849-8.62l.002-.002-2.868-3.173h0Z"/>
          <path style={styles.red} d="M48.495,46.55l2.234.564c-.469,1.834-1.31,3.235-2.527,4.197s-2.703,1.446-4.461,1.446c-1.82,0-3.299-.369-4.437-1.11-1.141-.741-2.008-1.813-2.601-3.218-.595-1.405-.893-2.913-.893-4.525,0-1.758.336-3.292,1.008-4.599.672-1.308,1.627-2.303,2.868-2.982,1.239-.679,2.603-1.019,4.092-1.019,1.689,0,3.108.431,4.261,1.289,1.15.86,1.953,2.07,2.406,3.628l-2.198.519c-.391-1.229-.96-2.122-1.703-2.682-.746-.56-1.682-.841-2.808-.841-1.298,0-2.382.312-3.254.934s-1.484,1.458-1.836,2.503c-.353,1.048-.529,2.129-.529,3.242,0,1.436.21,2.689.626,3.759.419,1.072,1.069,1.872,1.951,2.401.884.529,1.839.796,2.868.796,1.25,0,2.31-.36,3.177-1.081.862-.726,1.451-1.798,1.758-3.218Z"/>
        </g>
        {!collapsed && (
          <g id="full-name">
            <g style={styles.red}>
              <path d="M56.197,39.154v3.868c.65-.76,1.429-1.141,2.332-1.141.464,0,.884.086,1.255.257.374.171.655.393.843.66.188.269.319.564.388.891.069.326.105.831.105,1.515v4.473h-2.017v-4.028c0-.798-.038-1.305-.114-1.522s-.212-.386-.405-.512-.436-.191-.729-.191c-.336,0-.634.081-.898.243s-.455.407-.579.736c-.121.329-.183.812-.183,1.453v3.818h-2.017v-10.523h2.02v.002h-.001Z"/>
              <path d="M67.583,47.25l2.01.338c-.257.736-.667,1.298-1.224,1.684s-1.255.579-2.094.579c-1.327,0-2.306-.433-2.944-1.3-.503-.693-.753-1.57-.753-2.627,0-1.262.331-2.253.991-2.968s1.496-1.074,2.506-1.074c1.134,0,2.029.374,2.684,1.124s.969,1.896.941,3.442h-5.054c.014.598.176,1.065.488,1.396.312.333.698.498,1.162.498.317,0,.581-.086.798-.257.214-.174.376-.45.488-.834h0ZM67.697,45.211c-.014-.584-.164-1.027-.453-1.331s-.636-.455-1.048-.455c-.441,0-.805.16-1.091.481-.288.322-.429.755-.424,1.305h3.016Z"/>
              <path d="M71.193,42.053h1.86v1.041c.665-.81,1.458-1.212,2.377-1.212.488,0,.912.1,1.27.302s.653.505.884.912c.336-.407.695-.71,1.084-.912.388-.2.803-.302,1.241-.302.56,0,1.034.114,1.422.341s.676.562.869,1c.138.326.207.853.207,1.579v4.873h-2.017v-4.356c0-.755-.069-1.243-.207-1.465-.186-.288-.474-.431-.862-.431-.283,0-.548.086-.798.257s-.429.424-.538.757c-.11.333-.164.857-.164,1.577v3.661h-2.017v-4.178c0-.741-.036-1.22-.107-1.436-.071-.214-.183-.376-.333-.481s-.355-.157-.615-.157c-.312,0-.591.083-.841.25-.248.167-.426.41-.536.724-.107.317-.162.841-.162,1.572v3.704h-2.017v-7.619h.002-.002Z"/>
              <path d="M83.948,45.756c0-.669.164-1.32.495-1.946s.798-1.105,1.403-1.436,1.281-.495,2.027-.495c1.153,0,2.098.374,2.834,1.124s1.105,1.696,1.105,2.839c0,1.153-.372,2.11-1.117,2.868-.743.76-1.682,1.139-2.811,1.139-.698,0-1.365-.157-1.998-.474s-1.117-.779-1.446-1.389-.493-1.353-.493-2.229h0ZM86.016,45.864c0,.755.179,1.336.538,1.736s.803.603,1.329.603.967-.2,1.324-.603.536-.986.536-1.751c0-.746-.179-1.322-.536-1.722-.357-.403-.798-.603-1.324-.603s-.969.2-1.329.603-.538.981-.538,1.736h0Z"/>
              <path d="M96.891,42.053v1.608h-1.379v3.073c0,.622.012.984.04,1.089.026.102.086.188.179.255s.207.1.341.1c.186,0,.457-.064.812-.193l.171,1.565c-.469.2-1,.302-1.593.302-.364,0-.691-.062-.984-.183s-.505-.281-.643-.474c-.136-.193-.231-.455-.283-.786-.043-.233-.064-.707-.064-1.422v-3.323h-.927v-1.608h.927v-1.515l2.025-1.177v2.691h1.379v-.002h0Z"/>
              <path d="M98.292,41.019v-1.867h2.017v1.867h-2.017ZM98.292,49.677v-7.624h2.017v7.624h-2.017Z"/>
              <path d="M101.91,45.756c0-.669.164-1.32.495-1.946s.798-1.105,1.403-1.436,1.281-.495,2.027-.495c1.153,0,2.098.374,2.834,1.124s1.105,1.696,1.105,2.839c0,1.153-.372,2.11-1.117,2.868-.743.76-1.682,1.139-2.811,1.139-.698,0-1.365-.157-1.998-.474s-1.117-.779-1.446-1.389-.493-1.353-.493-2.229h0ZM103.977,45.864c0,.755.179,1.336.538,1.736s.803.603,1.329.603.967-.2,1.324-.603.536-.986.536-1.751c0-.746-.179-1.322-.536-1.722-.357-.403-.798-.603-1.324-.603s-.969.2-1.329.603-.538.981-.538,1.736h0Z"/>
              <path d="M118.29,49.677h-2.017v-3.89c0-.824-.043-1.355-.129-1.598-.086-.241-.226-.429-.419-.564-.193-.133-.426-.2-.7-.2-.35,0-.662.095-.941.288-.279.191-.467.445-.572.76-.102.317-.155.9-.155,1.751v3.454h-2.017v-7.624h1.875v1.119c.665-.862,1.503-1.291,2.513-1.291.445,0,.853.081,1.22.241.369.16.648.364.836.615.188.248.322.531.395.848s.112.767.112,1.358v4.735h0v-.002Z"/>
            </g>
            <g>
              <path d="M104.125,57.754v-5.285h3.918v.893h-2.851v1.172h2.653v.891h-2.653v1.439h2.953v.891h-4.021,0Z"/>
              <path d="M109.079,57.754v-5.242h1.067v4.352h2.653v.891h-3.72Z"/>
              <path d="M113.571,57.754v-5.285h1.038l2.163,3.53v-3.53h.991v5.285h-1.072l-2.132-3.447v3.447h-.988Z"/>
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default ChemotionLogo;
